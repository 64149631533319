import { Component, Input } from '@angular/core';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { TagResponseApi } from '@app/galgo-api/models';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { ItemTypes } from '@shared/models/item-types.enum';
import { IDetailConfig } from './detail-config';

@Component({
  selector: 'ty-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent {

  get media(): MediaItem<MediaItemTypes> {
    return this._media;
  }

  @Input() set media(media: MediaItem<MediaItemTypes>) {
      this._media = media;
  }
  @Input() detailConfiguration: IDetailConfig;

  private _media: MediaItem<MediaItemTypes>;
  private currentUrl: string;

  get shortDescription(): string {
    return this.media?.shortDescription;
  }

  get description(): string {
    return this.media?.description;
  }

  get date(): number {
    switch (this.media?.itemType) {
      case ItemTypes.Collection:
        return Number(String(this.media?.date).split('-')[0]);
      case ItemTypes.Video:
        return Number(this.media?.date);
      default:
        return undefined;
    }
  }

  get duration(): number {
    return this.media?.duration;
  }

  get isLabel(): boolean {
    return !!this.label?.length;
  }

  get label(): string[] {
    const labels: string[] = [];
    this.media?.layout?.label?.forEach((label: TagResponseApi) => {
      labels.push(label.value);
    });
    return labels;
  }

  get live(): boolean {
    return this.media?.live;
  }

  get pegi(): string {
    return this.media?.layout?.pegi;
  }

  get ppv(): string {
    return this.media?.layout?.ppv?.name?.value;
  }

  get productionEndDate(): number {
    return this.media?.extraData?.productionEndDate;
  }

  get productionStartDate(): number {
    return this.media?.extraData?.productionStartDate;
  }

  get tags(): string[] {
    return this.media?.layout?.label.map(label => label.value);
  }

  get subscription(): string {
    return this.media?.layout?.subscription?.name?.value;
  }

  get descriptionSelector(): string {
    return this.shortDescription ?? this.description;
  }

  get title(): string {
    return this.media?.title;
  }

  get thumbnail(): string {
    return this.media?.thumbnail?.landscapes[0]?.url;
  }

  get topics(): string[] {
    this.currentUrl = this.browsingHistoryService.currentURL;
    return this.currentUrl === AppRoutes.home ? [] : this.media?.layout?.topic.map(label => label.value) || [];
  }

  get showDescription(): boolean {
    return this.detailConfiguration?.showDescription;
  }

  constructor(private browsingHistoryService: BrowsingHistoryService) {
    this.detailConfiguration = {showDescription:false};
  }

}
