export enum LanguagesTraductionsAvailables {
  AR = 'ar',
  BG = 'bg',
  BR = 'br',
  CS = 'cs',
  DA = 'da',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FI = 'fi',
  FR = 'fr',
  HE = 'he',
  HU = 'hu',
  ID = 'id',
  IS = 'is',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  LT = 'lt',
  LV = 'lv',
  MS = 'ms',
  NL = 'nl',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  RO = 'ro',
  RU = 'ru',
  SK = 'sk',
  SV = 'sv',
  TH = 'th',
  UK = 'uk',
  VI = 'vi',
  ZH = 'zh'
}
