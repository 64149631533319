import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ImageSizeApi, VideoAccessApi } from '@api/models';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { TranslateService } from '@ngx-translate/core';
import { TagType } from '../../models/tag-type.enum';
import { ITag } from '../../models/tag.interface';
import { liveTag, ppvTag, suscriptionTag } from './media-thumb.config';

@Component({
  selector: 'ty-media-thumb.co-media-thumb',
  templateUrl: './media-thumb.component.html',
  styleUrls: ['./media-thumb.component.scss']
})
export class MediaThumbComponent implements OnInit {
  @ViewChild('myImage') myImage: ElementRef;

  @Input() set content(value: MediaItem<MediaItemTypes>) {
    this.internalItem = value;
  }

  @Input() seeMore: boolean;

  focused: boolean;
  suscriptionTag: ITag;
  ppvTag: ITag;
  src: string;
  liveTag: ITag;

  get title(): string {
    return this.internalItem?.title;
  }

  get thumbnail(): string {
    const landscapes = this.internalItem?.thumbnail?.landscapes;
    const landscape = this.internalItem?.thumbnail?.landscape;
    const preferenceOrder = [ImageSizeApi.Low, ImageSizeApi.Medium, ImageSizeApi.Original];

    if (landscapes?.length > 0) {
      const found = preferenceOrder.map(preference => landscapes.find(value => value.size === preference)).find(url => url);
      return found?.url ?? this.defaultLandscape;
    }
    return landscape ?? this.defaultLandscape;
  }

  get hasSubscription(): boolean {
    return this.internalItem?.access !== VideoAccessApi.Public;
  }

  get subscription(): string {
    return this.internalItem?.layout?.subscription?.name?.value;
  }
  get ppv(): string {
    return this.internalItem?.layout?.ppv?.name?.value;
  }

  get ranking(): number {
    return this.internalItem?.ranking;
  }

  get tagTypeSubscription(): TagType {
    return TagType.subscription;
  }

  get tagTypePpv(): TagType {
    return TagType.ppv;
  }

  get defaultLandscape(): string {
     return this.customerDataService.defaultLandscape;
  }

  get isLive(): boolean {
    return this.internalItem?.live;
  }

  internalItem: MediaItem<MediaItemTypes>;

  constructor(
    public customerDataService: CustomerDataService,
    private translate: TranslateService
  ) {
    this.suscriptionTag = { ...suscriptionTag };
    this.liveTag = { ...liveTag };
    this.ppvTag = { ...ppvTag };
  }

  ngOnInit() {
    this.setCurrentTags();
    this.src = this.getImageUrl();
  }

  setDefaultLandscape() {
    this.myImage.nativeElement.src = this.defaultLandscape;
  }

  getImageUrl(): string {
    const url = this.thumbnail;
    return url ?? '';
  }

  mouseEnter() {
    this.focused = true;
  }

  mouseLeave() {
    this.focused = false;
  }

  private setCurrentTags() {
    this.suscriptionTag.text = this.subscription;
    this.ppvTag.text = this.ppv;
    this.liveTag.text = this.translate.instant('details.live');
  }
}
