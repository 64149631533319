import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { ImageResolutionApi, ImageSizeApi } from '@app/galgo-api/models';

@Component({
  selector: 'ty-background-thumbnail.co-background-thumbnail',
  templateUrl: './background-thumbnail.component.html',
  styleUrls: ['./background-thumbnail.component.scss']
})
export class BackgroundThumbnailComponent {
  @Input() thumbnail: string | ImageResolutionApi[];
  @Input() isHeaderHidden: boolean;
  @ViewChild('thumbElement') thumbElement!: ElementRef;


  backgroundImageError = false;

  get defaultBanner(): string {
    return this.customerDataService.defaultBanner;
  }

  get banner(): string {
    if( typeof this.thumbnail !== 'string'){
      const landscapes = this.thumbnail;
      const preferenceOrder = [ImageSizeApi.Medium, ImageSizeApi.High, ImageSizeApi.Original];
      if (landscapes?.length > 0) {
        const found = preferenceOrder.map(preference => landscapes.find(value => value.size === preference)).find(url => url);
        return found?.url ?? this.defaultBanner;
      }
    }else{
      return this.thumbnail ?? this.defaultBanner;
    }
    return this.defaultBanner;
  }

  constructor(private customerDataService: CustomerDataService) {}

}


