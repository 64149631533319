import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { NavigationType } from './models/navigation.types';
import { NavigationComponent } from './navigation.component';

@Directive({
  selector: '[appNavigation]',
})
export class NavigationDirective implements AfterViewInit {
  @Input() navigableId: number;
  @Input() navigable: boolean;

  private type: NavigationType;
  private active: boolean;
  private parentNode: NavigationComponent;

  constructor(public el: ElementRef) {
    this.el = el;
    this.navigableId = -1;
    this.navigable = true;
    this.type = NavigationType.directive;
    this.active = false;
    this.parentNode = undefined;
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.tabIndex = this.navigableId;
  }

  setParentNode(node: NavigationComponent): void {
    this.parentNode = node;
  }

  getParentNode(): NavigationComponent {
    return this.parentNode;
  }

  getType(): NavigationType {
    return this.type;
  }

  getNavigableId(): number {
    return this.navigableId;
  }

  isActive(): boolean {
    return this.active;
  }

  activate(): boolean {
    this.active = true;
    this.el.nativeElement.focus({ preventScroll: true });
    return true;
  }

  deactivate(): void {
    if (this.active) {
      this.active = false;
      this.el.nativeElement.blur();
    }
  }

  getLeafOffsetTop(): number {
    return (this.el.nativeElement as HTMLElement).offsetTop;
  }

  getLeafOffsetLeft(): number {
    return (this.el.nativeElement as HTMLElement).offsetLeft;
  }

  getOffsetTop(): number {
    return (this.el.nativeElement as HTMLElement).offsetTop;
  }

  getOffsetLeft(): number {
    return (this.el.nativeElement as HTMLElement).offsetLeft;
  }

  isInsideViewport(): boolean {
    const rect = this.el.nativeElement.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
