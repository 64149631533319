import { Component, Input } from '@angular/core';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { ImageSizeApi } from '@app/galgo-api/models';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { IDetailConfig } from '@shared/components/detail/detail-config';
import { homeDetailConfig } from './home-detail.config';

@Component({
  selector: 'ty-home-detail',
  templateUrl: './home-detail.component.html',
  styleUrls: ['./home-detail.component.scss']
})
export class HomeDetailComponent {

  @Input() set selectedContent(value: MediaItem<MediaItemTypes>) {
      this._selectedContent = value;
  }

  get selectedContent(): MediaItem<MediaItemTypes> {
    return this._selectedContent;
  }


  get defaultThumbnail(): string {
    return this.customerDataService.defaultLandscape;
  }

  homeDetailConfig: IDetailConfig;

  private _selectedContent: MediaItem<MediaItemTypes>;

  get thumbnail(): string {
    const landscapes = this.selectedContent?.thumbnail?.landscapes;
    const landscape = this.selectedContent?.thumbnail?.landscape;
    const preferenceOrder = [ImageSizeApi.Medium, ImageSizeApi.High, ImageSizeApi.Original];

    if (landscapes && landscapes.length > 0) {
      const found = preferenceOrder.map(preference => landscapes.find(value => value.size === preference)).find(url => url);
      return found?.url ?? this.defaultThumbnail;
    }
    return landscape || this.defaultThumbnail;
  }
  constructor(private customerDataService: CustomerDataService){
    this.homeDetailConfig = homeDetailConfig;
  }
}
