<div class="details-descriptions"
     id="details-descriptions"
     appNavigation
     [navigableId]="0">
  <div class="display">
    <div class="text-wrapper"
         id="text-wrapper">
      {{ detailDescription }}
    </div>
  </div>
</div>
