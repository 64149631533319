import { Component, ElementRef, Input } from '@angular/core';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { UntilDestroy } from '@ngneat/until-destroy';



@Component({
  selector: 'ty-details-descriptions',
  templateUrl: './details-descriptions.component.html',
  styleUrls: ['./details-descriptions.component.scss'],
  providers: [
    { provide: NavigationComponent, useExisting: DetailsDescriptionsComponent},
  ],
})
@UntilDestroy()
export class DetailsDescriptionsComponent extends NavigationComponent {
  @Input() detailDescription: string;
  textDescription: string;
  navigableIndex: number;

  constructor(public el: ElementRef) {
    super(el);
  }


  onDownKey(): boolean {
    const d = document.getElementById('text-wrapper');
    d.scrollTop += 50;
    return true;
  }

  onUpKey(): boolean {
    const d = document.getElementById('text-wrapper');
    d.scrollTop -= 50;
    if (d.scrollTop === 0) {
      this.selectPreviousChildNode();
      return false;
    }
    return true;
  }
}
