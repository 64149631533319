import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GalgoEvent } from '@app/analytics/models/galgo-event.interface';
import { Container } from '@app/core/models/container.model';
import { MediaItem, MediaItemTypes } from '@app/core/models/media-item.model';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { LanguageService } from '@app/core/services/language.service';
import { ContainerSimpleApi, ErrorResponseApi } from '@app/galgo-api/models';
import { Carousel } from '@app/shared/features/carousel/model/carousel.model';
import { Collection } from '@app/shared/models/collection/collection.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { SeeMoreService } from '../services/see-more.service';
import { SeeMoreConfiguration } from './see-more.config';

@UntilDestroy()
@Component({
  templateUrl: './see-more.component.html',
  styleUrls: ['./see-more.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: SeeMoreComponent}]
})
export class SeeMoreComponent extends NavigationComponent implements OnInit {

  @ViewChild('seeMore') seeMore!: ElementRef;

  carouselItems: MediaItem<MediaItemTypes>[];
  galgoEvent: GalgoEvent;
  isDataReceived: boolean;
  scrollTop: number;
  setInitialFocus: boolean;
  showBackButton: boolean;
  title: string;
  private carouselId: string;

  get navigableGrid(): boolean {
    return SeeMoreConfiguration.navigableGrid;
  }

  constructor(
    public el: ElementRef,
    private customerDataService: CustomerDataService,
    private featureFlagsService: FeatureFlagsService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private router: Router,
    private seeMoreService: SeeMoreService,
    private language: LanguageService,
    private browsingHistoryService: BrowsingHistoryService
  ) {
    super(el);
    this.galgoEvent = GalgoEvent.videoFromSeeMore;
    this.scrollTop = 0;
    this.showBackButton = this.featureFlagsService?.currentFeaturesValue?.showBackButton;
    this.setDefaultActiveChildNodeId(1);
  }

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe(
      (params: Params) => {
        this.carouselId = params.id;
        this.getCarouselMetas();
        this.logger.debug(
          'SeeMoreComponent -> GetCarouselIdFromUrl(): Success',
          params
        );
      },
      (error: ErrorResponseApi) => {
        this.logger.error(
          'SeeMoreComponent -> GetCarouselIdFromUrl(): Failed',
          error
        );
        this.router.navigate([AppRoutes.forbidden]);
      }
    );
  }

  onUpKey(): boolean {
      this.selectPreviousChildNode();
      return true;
  }

  onDownKey(): boolean {
      this.selectNextChildNode();
      return true;
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }

  updateScroll(): void {
    const focused = this.getChildNodeByPosition(this.getSelectedChildNodeId());
    if (focused) {
      const scrollTop = focused.getLeafOffsetTop();
      if (scrollTop !== this.scrollTop) {
        this.scrollTop = scrollTop;
        this.seeMore.nativeElement.style.transform = `translateY(-${this.scrollTop}px)`;
      }
    }
  }

  private getCarouselMetas() {
    this.seeMoreService
      .getContainerById(this.carouselId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (carousel: Carousel) => {
          if (carousel.customRoute && carousel.customRoute === 'video/playing') {
            this.getVideoPlayingContainer();
          } else {
            this.title = carousel?.title;
            this.isDataReceived = true;
            const subContainers = this.filterCollections(carousel);
            const videos = carousel?.videos || [];
            this.carouselItems = [...subContainers, ...videos];
          }
        },
        (error: HttpErrorResponse) => {
          this.logger.error(
            'SeemoreComponent -> GetCaruselMetas(): Failed',
            error
          );
        }
      );
  }

      private getVideoPlayingContainer() {
        this.seeMoreService
        .getContainerVideoPlaying(this.language.currentLang)
        .pipe(untilDestroyed(this))
        .subscribe(
          (carousel: ContainerSimpleApi) => {
            this.title = carousel.name;
            this.isDataReceived = true;
            this.carouselItems = carousel.videos as any;
          },
      (error: HttpErrorResponse) => {
        this.logger.error(
          'SeemoreComponent -> GetVideoPlayingContainer(): Failed',
          error
        );
      }
    );
  }

  private filterCollections(carousel: Container): Collection[] {
    if (this.customerDataService.disableEmptyFilters){
      return carousel.subContainers;
    } else {
      return carousel.subContainers
      ?.map((collection) => new Collection(collection))
      ?.filter((collection: Collection) => {
        if (collection.subContainers?.length) {
          collection.subContainers = this.filterCollections(collection);
          return !!collection.subContainers?.length;
        }
        return collection.numOfChapters > 0;
      });
    }
  }
}
