import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ImageSizeApi, VideoAccessApi } from '@api/models';
import { CustomerDataService } from '@app/core/services/customer-data.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { ITag } from '@app/shared/models/tag.interface';
import { MediaItem, MediaItemTypes } from '@core/models/media-item.model';
import { TranslateService } from '@ngx-translate/core';
import { liveTag, ppvTag, suscriptionTag } from '../media-thumb/media-thumb.config';


@Component({
  selector: 'ty-grid-thumb.co-grid-thumb',
  templateUrl: './grid-thumb.component.html',
  styleUrls: ['./grid-thumb.component.scss']
})
export class GridThumbComponent implements OnInit{

  @ViewChild('thumbElement') thumbElement!: ElementRef;

  errorCharge = false;
  focused: boolean;
  internalItem: MediaItem<MediaItemTypes>;
  liveTag: ITag;
  ppvTag: ITag;
  src: string;
  suscriptionTag: ITag;
  thumbnail: string;
  useThumbnailTitle: boolean;


  @Input() set item(value: MediaItem<MediaItemTypes>) {
    this.internalItem = value;
  }

  get title(): string {
    return this.internalItem?.title ?? this.internalItem?.name;
  }

  get thumbnailTitle(): string {
    return this.internalItem?.thumbnail?.title;
  }

  get defaultPortrait(): string {
    return this.customerDataService.defaultPortrait;
  }

  get hasSubscription(): boolean {
    return this.internalItem?.access !== VideoAccessApi.Public;
  }

  get subscription(): string {
    return this.internalItem?.layout?.subscription?.name?.value;
  }

  get ppv(): string {
    return this.internalItem?.layout?.ppv?.name?.value;
  }
  get ranking(): number {
    return this.internalItem?.ranking;
  }

  get isLive(): boolean {
    return this.internalItem?.live;
  }

  constructor(
    public customerDataService: CustomerDataService,
    private featureFlagsService: FeatureFlagsService,
    private translate: TranslateService,
  ) {
    this.liveTag = { ...liveTag };
    this.ppvTag = { ...ppvTag };
    this.suscriptionTag = { ...suscriptionTag };
    this.useThumbnailTitle = this.featureFlagsService?.currentFeaturesValue?.useThumbnailTitle;
  }
  ngOnInit(): void {
    this.setCurrentTags();
    this.loadThumbnail();
  }

  loadThumbnail(): void {
    const thumbnailUrl = this.getThumbnailUrl();
    this.checkImageExists(thumbnailUrl).then(exists => {
      if (!exists) {
        this.thumbElement.nativeElement.style.backgroundImage = `url(${this.defaultPortrait})`;
      } else {
        this.thumbElement.nativeElement.style.backgroundImage = `url(${thumbnailUrl})`;
      }
    });
  }

  mouseEnter() {
    this.focused = true;
  }

  mouseLeave() {
    this.focused = false;
  }

  getThumbnailUrl(): string {
    const portraits = this.internalItem?.thumbnail?.portraits;
    const portrait = this.internalItem?.thumbnail?.portrait;
    const preferenceOrder = [ImageSizeApi.Low, ImageSizeApi.Medium, ImageSizeApi.Original];

    if (portraits && portraits.length > 0) {
      const found = preferenceOrder.map(preference => portraits.find(value => value.size === preference)).find(url => url);
      return found?.url ?? this.defaultPortrait;
    }
    return portrait ?? this.defaultPortrait;
  }

  checkImageExists(url: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  private setCurrentTags() {
    this.suscriptionTag.text = this.subscription;
    this.ppvTag.text = this.ppv;
    this.liveTag.text = this.translate.instant('details.live');
  }


}
