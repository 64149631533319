<div id="carousel">
  <div #carousel
       class="carousel-wrapper">
    <ty-virtual-scroll class="virtual-scroll"
                       #virtualScroll
                       [items]="virtualScrollItems"
                       [numVisibleItems]="6"
                       [toleranceAfter]="2"
                       [toleranceBefore]="1"
                       [firstSelectedChildNodeId]="firstSelectedChildNodeId">
      <div id="carousel-item"
           *ngFor="let item of virtualScroll.visibleItems; let index = index; trackBy: trackByFn">
        <ty-media-thumb class="co-media-thumb"
                        [content]="item.data"
                        appNavigation
                        (click)="navigateToVideoDetails(item.data)"
                        [navigableId]="item.id"
                        (mouseenter)="carouselItemSelectedHover(item.data)"
                        (mouseleave)="cancelTimeout()"
                        (focus)="carouselItemSelected(item.data)">
        </ty-media-thumb>
        <div class="underline-active-item"></div>
      </div>
      <div id="carousel-item"
           *ngIf="virtualScrollItems.length >= 15">
        <ty-media-thumb class="co-media-thumb"
                        [content]="carouselItem"
                        appNavigation
                        (click)="navigateToCarouselDetails()"
                        [navigableId]="15"
                        [seeMore]="true">
        </ty-media-thumb>
        <div class="underline-active-item"></div>
        <span class="see-more"
              (click)="navigateToCarouselDetails()">Ver más</span>
      </div>
    </ty-virtual-scroll>
  </div>
  <div class="arrow left-arrow"
       (mouseenter)="onStartScrollLeft()"
       (mouseleave)="onStopScrollLeft()"
       [ngClass]="{ 'arrow-disabled': !isScrollLeftEnabled() }"
       *ngIf="isCursorMode || isBrowser">
    <i class="gi-lg gi-return-icon"></i>
  </div>
  <div class="arrow right-arrow"
       (mouseenter)="onStartScrollRight()"
       (mouseleave)="onStopScrollRight()"
       [ngClass]="{ 'arrow-disabled': !isScrollRightEnabled() }"
       *ngIf="isCursorMode || isBrowser">
    <i class="gi-lg gi-return-icon rotate"></i>
  </div>
</div>