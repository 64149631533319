import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth-custom/auth.service';
import { UserService } from '@app/auth/services/user/user.service';
import { Language } from '@app/core/models/language.model';
import { ISettings } from '@app/core/models/settings.interface';
import { AppRoutes } from '@app/core/navigation/config/app-routes.enum';
import { NavigationComponent } from '@app/core/navigation/navigation.component';
import { BrowsingHistoryService } from '@app/core/navigation/services/browsing-history.service';
import { FeatureFlagsService } from '@app/core/services/feature-flags.service';
import { LanguageService } from '@app/core/services/language.service';
import { LOGOUT_MODAL_DATA } from '@app/shared/components/modal/config';
import { ModalActions } from '@app/shared/components/modal/config/modal-actions.enum';
import { ModalService } from '@app/shared/components/modal/services/modal.service';
import { CacheService } from '@app/shared/services/cache.service';
import { CustomerDataService } from '@core/services/customer-data.service';
import { EnvironmentService } from '@core/services/environment.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NGXLogger } from 'ngx-logger';
import { first } from 'rxjs/operators';
import { Settings } from './models/settings.interface';
import { SettingsService } from './services/settings.service';
@UntilDestroy()
@Component({
  selector: 'ty-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [{ provide: NavigationComponent, useExisting: SettingsComponent }],

})
export class SettingsComponent extends NavigationComponent{
  appRoutes: typeof AppRoutes;
  data: Settings;
  brandName: string;
  appVersion: string;
  showLanguage: boolean;
  showPoweredByGalgo: boolean;
  poweredByGalgo: string;
  languages: Language[];

  get userLangDescription(): string {
    const userLang = this.getUserLang();
    const language = this.languages?.find(lang => lang.id === userLang);
    return language ? language.description : '';
  }

  constructor(
    private authCustomService: AuthService,
    private customerDataService: CustomerDataService,
    private cacheService: CacheService,
    private environmentService: EnvironmentService,
    private featureFlagsService: FeatureFlagsService,
    private settingsService: SettingsService,
    private languageService: LanguageService,
    private userService: UserService,
    private router: Router,
    private modalService: ModalService,
    public logger: NGXLogger,
    private browsingHistoryService: BrowsingHistoryService,
    public el: ElementRef
  ) {
    super(el);
    this.data = {};
    this.brandName = '';
    this.showLanguage = false;
    this.showPoweredByGalgo = this.featureFlagsService.currentFeaturesValue?.showPoweredByGalgo;
    this.poweredByGalgo = this.showPoweredByGalgo ? 'Powered by Galgo. ' : '';
    this.appRoutes = AppRoutes;
    this.appVersion = `${this.poweredByGalgo}Version ${this.environmentService.version}`;

    this.setDataFromCustomer();

    this.getSettings()
      .pipe(first(), untilDestroyed(this))
      .subscribe({
        next: (settings: ISettings) => {
          this.languages = settings.availableLanguages;
        },
        error: (error: HttpErrorResponse) => {
          this.logger.error('AppComponent -> GetEssentialData(): Failed', error);
          throw new Error('Error obtaining settings');
        }
      });

    this.settingsService.languageSet$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.setDataFromUser();
      },
    });
  }



  onEnterKey(): boolean {
    (this.getChildNodeById(this.getSelectedChildNodeId()).el.nativeElement as HTMLElement).click();
    return true;
  }


  onUpKey(): boolean {
    if (this.getSelectedChildNodeId() === 4) {
      return true;
    }
    this.selectPreviousChildNode();
    return true;
  }

  onRightKey(): boolean {
    this.showLanguage = false;
    this.selectChildNodeById(4);
    return true;
  }

  onDownKey(): boolean {
    if (this.getSelectedChildNodeId() === 3) {
      return true;
    }
    this.selectNextChildNode();
    return true;
  }
  onLeftKey(): boolean {
    if (this.getSelectedChildNodeId() === 4) {
      this.selectChildNodeById(0);
      return true;
    }else{
      this.showLanguage = false;
      return false;
    }
  }

  onBackKey(): boolean {
    this.browsingHistoryService.goBack();
    return true;
  }

  selectlanguage(): void {
    this.showLanguage = false;
  }

  onShowLanguageChange(newShowLanguage: boolean): void {
    this.showLanguage = newShowLanguage;
  }

  logout(): void {
    this.browsingHistoryService.removeAllFocusData();
    this.cacheService.clearCache();
    this.authCustomService.logout();
    this.router.navigate([AppRoutes.onboarding]);
  }

  openLogoutModal(): void {
    this.modalService.open(LOGOUT_MODAL_DATA, this, (action: ModalActions) => {
      switch (action) {
        case ModalActions.logout:
          this.logout();
          break;
        default:
          this.selectChildNodeById(0);
          break;
      }
    });
  }


  goTo(route: string): void {
    this.router.navigate([route]);
  }

  private getSettings() {
    return this.settingsService.getSettingsFromApi().pipe(untilDestroyed(this));
  }

  private getUserLang(): string {
    return this.userService.currentUserLanguage || this.customerDataService.defaultLanguage;
  }

  private setDataFromCustomer(): void {
    this.data.brandEmail = this.customerDataService.email;
    this.data.brandPhone = this.customerDataService.phone;
    this.data.brandWebsite = this.customerDataService.website;
    this.brandName = this.customerDataService.enterpriseName;
  }

  private setDataFromUser(): void {
    this.data.userEmail = this.userService.currentUser?.email;

    const language = this.languageService.currentLang;
    if (language) {
      this.data.userLanguage = language;
    } else {
      this.data.userLanguage = this.customerDataService.defaultLanguage;
    }
  }

}
